/* global $ */
/* global EditableSelect */
/* global wizeFloorExternal */
import React from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'

import Papa from 'papaparse'

import messagesDa from './components/translations/da.json'
import messagesEn from './components/translations/en.json'
import messagesJa from './components/translations/ja.json'
import messagesKo from './components/translations/ko.json'
import messagesNl from './components/translations/nl.json'
import messagesNb from './components/translations/nb.json'
import messagesSv from './components/translations/sv.json'
import messagesTr from './components/translations/tr.json'
import messagesZh from './components/translations/zh.json'

import '@formatjs/intl-relativetimeformat/polyfill'

import { Activity } from './components/activity/Activity.jsx'
import { Users } from './components/users/Users.jsx'

import SoundManager from './util/SoundManager'
import { languageManager, l } from './util/LanguageManager'
import { webService } from './util/WebServiceManager'

import AudioRecorder from './util/AudioRecorder'
import ControlPanelView from './view/ControlPanelView'
import GroupEditorView from './view/GroupEditorView'
import NotificationView from './view/NotificationView'
import RegisterView from './view/RegisterView'
import SoundPickerView from './view/SoundPickerView'
import TileDescriptionView from './view/TileDescriptionView'
import TileEditorView from './view/TileEditorView'
import TileEditorQuestionsView from './view/TileEditorQuestionsView'
import TileResultsAnswersView from './view/TileResultsAnswersView'

import 'magnific-popup'

export default class App {
  constructor() {
    window.app = this

    this.rootComponents = []
    this.formModified = false
    this.ignorePress = false
    this.lastPageHTML = ''
    this.lastPageUrl = ''
    this.manualStateChange = false

    this.initialize = this.initialize.bind(this)
    this.initializeForms = this.initializeForms.bind(this)
    this.initializeHistory = this.initializeHistory.bind(this)

    $(document).keyup(this.keyUp)
    $(document).ready(this.initialize)

    // Initialize history manager
    this.initializeHistory()

    // Load language strings
    languageManager.loadLanguage()

    // Show dialogue if form has been modified
    $(window).bind('beforeunload', () => {
      if (this.formModified === true && !window.ignoreLeavePage) {
        return ''
      }
    })

    // Place recorder correctly on resize
    $(window).resize(() => {
      this.getSoundPickerView().resized()
    })

    // Set version number (iOS)
    if (typeof window.version !== 'undefined' && window.version !== '') {
      $('#versionNumber').html(window.version)
    }
  }

  initialize() {
    // Update anchors
    $('a').off('click')
    $('a').click(event => {
      const element = $(event.currentTarget)
      if (typeof element === 'undefined') return false
      if (typeof element.attr('href') === 'undefined') return false
      if (this.ignorePress) {
        this.ignorePress = false
        return false
      }

      // Load page async
      if (element.hasClass('asyncLoad')) {
        window.manualStateChange = false

        // Check if form has been modified
        if (this.isFormModified()) {
          return false
        }
        this.formModified = false

        // Load page async
        this.showPage(element, element.attr('href'))
        return false
      } else if (element.attr('href').indexOf('http') !== -1) {
        const url = element.attr('href')
        document.location = url
        return false
      }
      return true
    })

    // Update image and video popup links
    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    })
    $('.popup-iframe').magnificPopup({
      type: 'iframe',
    })
    $('.image-link').magnificPopup({
      type: 'image',
    })

    this.initializeForms()

    window.initializeGlobal()
  }

  initializeForms() {
    // Activate editable selects
    if (typeof EditableSelect !== 'undefined') {
      EditableSelect.activateAll()
    }

    // Initialize type ahead text fields
    $('body').unbind()
    $('body').on('keyup', '.row-plangame-searchstring', event => {
      this.getTileEditorView().getSearchResults($(event.currentTarget), 0, event)
    })
    $('body').on('change', '.row-plangame-searchsection', event => {
      this.getTileEditorView().getSearchSectionResults($(event.currentTarget))
    })
    $('body').on('keyup', '.register-searchGroupName', event => {
      this.getRegisterView().getGroupResults($(event.currentTarget), event)
    })

    // Disable enter to submit form in editor
    $('#contentForm').unbind()
    $('#contentForm').bind('keyup keypress', e => {
      const code = e.keyCode || e.which
      if (code === 13 && e.target.nodeName !== 'TEXTAREA') {
        e.preventDefault()
        return false
      }
    })

    // Warn if user is leaving page
    $('#contentForm :input').unbind()
    $('#contentForm :input').change(() => {
      this.formModified = true
    })
    $("input[name='commit']").unbind()
    $("input[name='commit']").click(() => {
      this.formModified = false
    })

    // Initialize questions view
    this.getTileEditorQuestionsView().initialize()

    // Initialize expanding checkboxes
    $('input.expandCheckbox:checkbox').unbind()
    $('input.expandCheckbox:checkbox').change(event => {
      const element = $(event.currentTarget)
      const checked = element.is(':checked')
      const target = element.closest('.expandCheckboxContainer').find('.expandCheckboxTarget:first')
      if (checked) target.show()
      else target.hide()
    })

    // Initialize expanding radio button
    $('input.expandRadio:radio').unbind()
    $('input.expandRadio:radio').change(event => {
      const element = $(event.currentTarget)
      const checked = element.is(':checked')

      // Hide all other expandRadioTarget
      element
        .closest('.expandRadioParent')
        .find('.expandRadioTarget')
        .each((index, element) => {
          $(element).hide()
        })

      // Show selected expandRadioTarget
      const target = element.closest('.expandRadioContainer').find('.expandRadioTarget:first')
      if (checked) target.show()
      else target.hide()
    })
  }

  initializeHistory() {
    // Prepare history
    const History = window.History // Note: We are using a capital H instead of a lower h
    if (!History.enabled) {
      // History.js is disabled for this browser.
      // This is because we can optionally choose to support HTML4 browsers or not.
      return false
    }

    // Bind to StateChange Event
    History.Adapter.bind(window, 'statechange', () => {
      // Note: We are using statechange instead of popstate
      const State = History.getState()
      if (this.manualStateChange === true) {
        // back pressed
        this.formModified = false
        if (this.lastPageHTML !== '' && this.lastPageUrl === State.url) {
          $('#pageContent').html(this.lastPageHTML)
          this.lastPageHTML = ''
          this.lastPageUrl = ''
          this.initialize()

          // Scroll to top of window
          window.scrollTo(0, 0)
          return
        }
        if (State.url.indexOf('app/?s=community') > 0 || State.url.indexOf('app/index.php?s=community') > 0)
          this.showPage($('a#community'), State.url)
        else if (State.url.indexOf('app/?s=user') > 0 || State.url.indexOf('app/index.php?s=user') > 0)
          this.showPage($('a#user'), State.url)
        else if (
          State.url.indexOf('app/?s=group') > 0 ||
          State.url.indexOf('app/index.php?s=group') > 0 ||
          State.url.endsWith('app') ||
          State.url.endsWith('app/') ||
          State.url.endsWith('app/index.php')
        )
          this.showPage($('a#group'), State.url)
        else this.showPage(undefined, State.url)
      }
      this.manualStateChange = true
    })
  }

  disablePress() {
    this.ignorePress = true
    return false
  }

  enablePress() {
    this.ignorePress = false
    return true
  }

  getAudioRecorder() {
    if (typeof this.audioRecorder === 'undefined') {
      this.audioRecorder = new AudioRecorder(this)
    }
    return this.audioRecorder
  }

  getControlPanel() {
    if (typeof this.controlPanel === 'undefined') {
      this.controlPanel = new ControlPanelView(this)
    }
    return this.controlPanel
  }

  getGroupEditorView() {
    if (typeof this.groupEditorView === 'undefined') {
      this.groupEditorView = new GroupEditorView(this)
    }
    return this.groupEditorView
  }

  getNotificationView() {
    if (typeof this.notificationView === 'undefined') {
      this.notificationView = new NotificationView()
    }
    return this.notificationView
  }

  getRegisterView() {
    if (typeof this.registerView === 'undefined') {
      this.registerView = new RegisterView(this)
    }
    return this.registerView
  }

  getSoundManager() {
    if (typeof this.soundManager === 'undefined') {
      this.soundManager = new SoundManager()
    }
    return this.soundManager
  }

  getSoundPickerView() {
    if (typeof this.soundPickerView === 'undefined') {
      this.soundPickerView = new SoundPickerView(this)
    }
    return this.soundPickerView
  }

  getTileDescriptionView() {
    if (typeof this.tileDescriptionView === 'undefined') {
      this.tileDescriptionView = new TileDescriptionView(this)
    }
    return this.tileDescriptionView
  }

  getTileEditorView() {
    if (typeof this.tileEditorView === 'undefined') {
      this.tileEditorView = new TileEditorView(this)
    }
    return this.tileEditorView
  }

  getTileEditorQuestionsView() {
    if (typeof this.tileEditorQuestionsView === 'undefined') {
      this.tileEditorQuestionsView = new TileEditorQuestionsView(this)
    }
    return this.tileEditorQuestionsView
  }

  getTileResultsAnswersView() {
    if (typeof this.tileResultsAnswersView === 'undefined') {
      this.tileResultsAnswersView = new TileResultsAnswersView(this)
    }
    return this.tileResultsAnswersView
  }

  isFormModified() {
    let stay = true
    if (this.formModified) {
      stay = confirm(l('UnsavedChanges'))
    }
    return !stay
  }

  keyUp(e) {
    if (typeof wizeFloorExternal !== 'undefined') {
      if (e.keyCode === '27' || e.keyCode === 27) {
        wizeFloorExternal.closeMenu()
      } // esc
    }
  }

  renderComponent(container, component) {
    const messages = {
      da: messagesDa,
      en: messagesEn,
      ja: messagesJa,
      ko: messagesKo,
      nl: messagesNl,
      nb: messagesNb,
      sv: messagesSv,
      tr: messagesTr,
      zh: messagesZh,
    }
    let language = document.documentElement.lang
    if (messages[language] === undefined) {
      language = 'en'
    }

    // Search for component
    for (const component of this.rootComponents) {
      if (component.container === container) {
        this.unmountComponent(component.container)
      }
    }

    // Render component
    const root = createRoot(container)
    root.render(
      <IntlProvider locale={language} messages={messages[language]}>
        {component}
      </IntlProvider>
    )
    this.rootComponents.push({ container: container, root: root })
    window.initializeGlobal()
  }

  showActivityView(container, regionId, groupId, year) {
    this.renderComponent(
      container,
      <Activity regionId={regionId} groupId={groupId} year={year} webService={webService} onLoad={this.initialize} />
    )
  }

  showPage(element, section) {
    let pageContent = $('#pageContent')

    // If menu item is set set active
    if (typeof element !== 'undefined' && element.hasClass('list-group-item')) {
      $('a').removeClass('active')
      element.addClass('active')
      $('#searchPanel').hide()
    }

    // Cancel any requests
    webService.abortLastRequest()

    // Show progress indicator
    $('#progressBar').html('<i class="fa fa-spinner fa-spin"></i>')
    webService.getPage(section, {
      success: html => {
        // If user has been logged out redirect
        if (html.indexOf('id="loginForm"') > 0) {
          window.location = section
          return
        }

        this.lastPageHTML = pageContent.html()
        this.lastPageUrl = section
        $('#progressBar').html('')

        // If history.js is enabled push state
        if (window.History.enabled) {
          window.History.pushState(null, document.title, section)
        }

        // Insert generated HTML into page content element
        pageContent.html(html)

        // Initialize elements
        this.initialize()

        // Scroll to top of window
        window.scrollTo(0, 0)
      },
    })
  }

  showUsersView(container, groupId) {
    this.renderComponent(container, <Users groupId={groupId} webService={webService} onLoad={this.initialize} />)
  }

  togglePanel(element, closeOthers) {
    const panel = element.closest('.panel').find('.panel-body')
    const panelToggle = element.closest('.panel').find('#toggle')
    const submenu = element.closest('.panel').hasClass('subpanel')

    this.getSoundPickerView().hideSoundRecorders()

    // Hide panel if it's already visible
    if (panel.is(':visible')) {
      panelToggle.removeClass('accordion-visible')
      panelToggle.addClass('accordion-hidden')
      panel.hide()
    } else {
      if (closeOthers) {
        // Hide other open panels on the top level
        if (!submenu) {
          $('.mainpanel')
            .find('.panel-body')
            .each((index, value) => {
              const togglePanel = $(value)
              const panelToggle = togglePanel.closest('.panel').find('#toggle')
              panelToggle.removeClass('accordion-visible')
              panelToggle.addClass('accordion-hidden')
              togglePanel.hide()
            })
        } else {
          element
            .closest('.mainpanel')
            .find('.subpanel')
            .find('.panel-body')
            .each((index, value) => {
              const togglePanel = $(value)
              const panelToggle = togglePanel.closest('.panel').find('#toggle')
              panelToggle.removeClass('accordion-visible')
              panelToggle.addClass('accordion-hidden')
              togglePanel.hide()
            })
        }
      }

      // Show panel
      panelToggle.removeClass('accordion-hidden')
      panelToggle.addClass('accordion-visible')
      panel.show()
    }

    // Hide any child panels
    panel.find('.panel-body').each(() => {
      const toggle = $(this).closest('.panel').find('#toggle')
      toggle.removeClass('accordion-visible')
      toggle.addClass('accordion-hidden')
      $(this).hide()
    })

    // Jump to selected panel
    element.jumpTo()
  }

  /**
   * Unmount a React component from the DOM.
   * @param {*} container The container element.
   */
  unmountComponent(container) {
    for (const component of this.rootComponents) {
      if (component.container === container) {
        component.root.unmount()
        break
      }
    }
    const index = this.rootComponents.findIndex(item => item.container === container)
    if (index !== -1) {
      this.rootComponents.splice(index, 1)
    }
  }

  /**
   * Validate CSV string.
   * @param {*} csvString CSV string to validate.
   * @returns true if valid, false otherwise.
   */
  validateCSV(csvString) {
    var results = Papa.parse(csvString, {
      delimiter: ',',
      quoteChar: '"',
    })
    return results['errors'].length === 0
  }
}

export let app
if (typeof window.app === 'undefined') {
  window.app = new App()
}
app = window.app
